import { Flex } from '@chakra-ui/react';
import { UseTableFiltersType } from 'hooks/useTableFilters';
import { CreateFiling } from 'pages/Filing/components/create-filing';
import { DownloadFiling } from 'pages/Filing/components/download-filing';
import { FilingFilterButton } from 'pages/Filing/components/FilingFilter/FilingPopover';
import FilingHistoryButton from 'pages/Filing/components/history-filings/filing-history-button';

type FilingTrackingToolbarProps = {
  tableFilters: UseTableFiltersType;
};
export const FilingTrackingToolbar = ({ tableFilters }: FilingTrackingToolbarProps) => {
  return (
    <Flex gap={3} alignItems={'top'}>
      <DownloadFiling />
      <FilingFilterButton tableFilters={tableFilters} />
      <FilingHistoryButton />
      <CreateFiling />
    </Flex>
  );
};

import { TableFilterPopover } from 'components/filter';
import { PopoverContentProps } from 'components/ui/popover';
import { UseTableFiltersType } from 'hooks/useTableFilters';
import { useState } from 'react';

import FilingFilter from './FilingFilter';

type FilingFilterButtonProps = {
  popoverContentProps?: PopoverContentProps;
  tableFilters: UseTableFiltersType;
};

export const FilingFilterButton = ({ tableFilters, popoverContentProps }: FilingFilterButtonProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { country_code, state_code, start_date, end_date } = tableFilters;
  const hasFilter = !!(country_code || state_code || start_date || end_date);

  return (
    <TableFilterPopover
      hasFilter={hasFilter}
      open={open}
      onOpenChange={({ open }) => setOpen(open)}
      popoverContentProps={popoverContentProps}
    >
      <FilingFilter
        tableFilters={tableFilters}
        onClose={() => {
          setOpen(false);
        }}
      />
    </TableFilterPopover>
  );
};

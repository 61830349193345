import { DialogTitle, Flex, Icon, IconButton, Spinner, Text, useDisclosure } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { exportFilingsSummary, FILINGS_STATE_KEY, getFilings } from 'apis/filing-apis';
import { ExcelIcon } from 'components/icons';
import { Button } from 'components/ui/button';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTrigger,
} from 'components/ui/dialog';
import { NativeSelectField, NativeSelectRoot } from 'components/ui/native-select';
import { Tooltip } from 'components/ui/tooltip';
import { CountryOptions } from 'constants/app-constants';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { useState } from 'react';
import { CountryCodeEnum } from 'types/shared-types';

export const DownloadFiling = () => {
  const { orgId } = useOrg();

  const { handleSuccessNotification } = useHandleNotification();
  const { open, onOpen, onClose } = useDisclosure();
  const [country, setCountry] = useState(CountryCodeEnum.US);

  const { data } = useQuery({
    queryKey: [FILINGS_STATE_KEY, orgId],
    queryFn: async () => {
      const res = await getFilings({ orgId });
      return res?.data;
    },
  });

  const downloadFile = (url: string, filename: string) => {
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const mutation = useMutation({
    mutationFn: () => {
      return exportFilingsSummary(orgId, country);
    },
    onSuccess: response => {
      if (import.meta.env.VITE_ENVIRONMENT === 'local') {
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const url = window.URL.createObjectURL(blob);
        const contentDisposition = response.headers['content-disposition'];
        const filename = contentDisposition.split('filename=')[1].split(';')[0].replace(/"/g, '');
        downloadFile(url, filename);
        handleSuccessNotification('Filing Report Downloaded');
      } else {
        handleSuccessNotification('Filing Report Export Started. Check your email for a download link.');
      }
      onCloseModal();
    },
  });

  const onCloseModal = () => {
    onClose();
    setCountry(CountryCodeEnum.US);
  };

  const filings = data?.items || [];
  const isDataEmpty = !filings.length;

  const handleDownloadClick = () => {
    mutation.mutate();
  };

  return (
    <DialogRoot
      closeOnInteractOutside={false}
      size={'lg'}
      open={open}
      onOpenChange={({ open }) => {
        if (!open) {
          onCloseModal();
        }
      }}
    >
      <DialogTrigger asChild>
        <Tooltip content={!isDataEmpty ? 'Export Filings' : 'No data to export'}>
          <IconButton
            disabled={isDataEmpty}
            aria-label={'Export Filings'}
            rounded={'sm'}
            variant={'ghost'}
            onClick={() => {
              onOpen();
            }}
          >
            {mutation.isPending ? (
              <Spinner />
            ) : (
              <Icon w="6" h="6">
                <ExcelIcon />
              </Icon>
            )}
          </IconButton>
        </Tooltip>
      </DialogTrigger>
      <DialogBackdrop />
      <DialogContent>
        <DialogCloseTrigger />
        <DialogHeader>
          <DialogTitle>Send Report</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <Flex direction="column" gap={3}>
            <Text>
              The report will be sent to your email address and will arrive in the next 1-2 minutes depending on how
              much data is in your organization.
            </Text>
            <Text fontWeight={500}>Country</Text>
            <NativeSelectRoot disabled>
              <NativeSelectField
                placeholder="Select Country"
                value={country || CountryCodeEnum.US}
                onChange={e => setCountry(e.target.value as CountryCodeEnum)}
              >
                {CountryOptions.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </NativeSelectField>
            </NativeSelectRoot>
          </Flex>
        </DialogBody>
        <DialogFooter>
          <Flex gap={4}>
            <Button variant={'outline'} color={'secondary'} onClick={onCloseModal}>
              Cancel
            </Button>
            <Button
              loading={mutation.isPending}
              variant={'solid'}
              color={'primary'}
              width={'90px'}
              onClick={handleDownloadClick}
            >
              Submit
            </Button>
          </Flex>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

import { Flex, Icon, Table, Text } from '@chakra-ui/react';
import { Tooltip } from 'components/ui/tooltip';
import React from 'react';
import { MdInfo } from 'react-icons/md';

type TableRow = {
  label: string;
  value: string | number | null | undefined;
  info?: string;
};

type TableProps = {
  title: string;
  data: TableRow[];
  valueFormatter?: (value: string | number) => string;
};

export const OverviewTable: React.FC<TableProps> = ({ title, data, valueFormatter = v => `${v}` }) => (
  <Flex flexDirection={'column'} gap={2}>
    <Text textStyle={'lg'} fontWeight={'500'}>
      {title}
    </Text>
    <Table.Root>
      <Table.Body>
        {data.map(({ label, value, info }) => (
          <Table.Row key={label}>
            <Table.Cell width={'100%'}>
              <Flex align="center" justifyContent={'space-between'}>
                <Text>
                  {label}
                  {info && (
                    <Tooltip content={info} positioning={{ placement: 'bottom-start' }}>
                      <Icon w={4} h={4} ml={1}>
                        <MdInfo />
                      </Icon>
                    </Tooltip>
                  )}
                </Text>
                <Text fontWeight={500}>{valueFormatter(value ?? '')}</Text>
              </Flex>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  </Flex>
);

import { Flex, Heading, HStack, Icon, Table, Text } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bulkApproveFilings, FILINGS_STATE_KEY } from 'apis/filing-apis';
import { Button } from 'components/ui/button';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { Tooltip } from 'components/ui/tooltip';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { MdInfo } from 'react-icons/md';
import { useSearchParams } from 'react-router-dom';
import { FilingApprovalStatsByIds, UnapprovedFilingMetaData } from 'types/shared-types';
import { formatCurrency } from 'utils/utils';

type FilingBulkApproveProps = {
  unapprovedFilingsMetaData: UnapprovedFilingMetaData;
  approvalStats?: FilingApprovalStatsByIds;
};

export const FilingBulkApprove = ({ unapprovedFilingsMetaData }: FilingBulkApproveProps) => {
  const { orgId } = useOrg();
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();

  const { isPending, mutate } = useMutation({
    mutationFn: (filing_ids: string[]) => {
      return bulkApproveFilings(orgId, filing_ids);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [FILINGS_STATE_KEY] });
      handleSuccessNotification('Filings successfully approved.');
      onClose();
    },
    onError: error => {
      handleFailNotification(error);
    },
  });

  if (!unapprovedFilingsMetaData) {
    return <></>;
  }

  const {
    count,
    ids,
    accumulated_amount,
    accumulated_total_tax_liability,
    accumulated_amount_sales,
    accumulated_amount_adjusted,
    accumulated_amount_discounts,
    accumulated_amount_tax_collected,
    accumulated_amount_calculated,
    accumulated_amount_fees,
    accumulated_amount_penalties,
  } = unapprovedFilingsMetaData;
  if (count < 2) {
    return <></>;
  }

  const isOpen = searchParams.get('bulk_approve') === 'true';

  const onOpen = () =>
    setSearchParams(params => {
      params.set('bulk_approve', 'true');
      return params;
    });

  const onClose = () =>
    setSearchParams(params => {
      params.delete('bulk_approve');
      return params;
    });

  const isCollectedTaxHigher = accumulated_amount_tax_collected > accumulated_amount_calculated;

  const overview = [
    { label: 'Sales', value: accumulated_amount_sales, info: null },
    {
      label: isCollectedTaxHigher ? 'Collected Tax' : 'Calculated Tax',
      value: accumulated_amount,
      info: `Calculated Tax = ${formatCurrency(accumulated_amount_calculated)}`,
    },
    { label: 'Adjustment', value: accumulated_amount_adjusted, info: null },
    { label: 'Fees', value: accumulated_amount_fees, info: null },
    { label: 'Penalties', value: accumulated_amount_penalties, info: null },
    { label: 'Discounts', value: accumulated_amount_discounts, info: null },
    {
      label: 'Total Liability',
      value: accumulated_total_tax_liability,
      info: 'Total Liability is calculated as the maximum of Calculated Tax or Collected Tax, plus any Adjustments, Penalties, and Fees, minus any applicable Discounts.',
    },
  ];

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" my={4} flexWrap={'wrap'} gap={4}>
        <HStack>
          <Text fontSize="md" fontWeight="medium">
            {`Approve ${count} unapproved filings on this page?`}
          </Text>
          <Button colorPalette="blue" w="100px" onClick={onOpen}>
            Approve
          </Button>
        </HStack>
        <Text fontSize="sm">
          Sum of &apos;Total Liability&apos; for unapproved filings on this page:{' '}
          <Text as="span" fontWeight="medium" fontSize="md">
            {formatCurrency(accumulated_total_tax_liability)}
          </Text>
        </Text>
      </Flex>
      <DialogRoot
        open={isOpen}
        onOpenChange={({ open }) => {
          if (!open) {
            onClose();
          }
        }}
      >
        <DialogBackdrop />
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Filing Details</DialogTitle>
          </DialogHeader>
          <DialogCloseTrigger />
          <DialogBody>
            <Text>{`Please review the details and submit when you're ready to file for ${formatCurrency(accumulated_total_tax_liability)}`}</Text>
            <Heading as="h3" size="xs" my={4}>
              Tax Overview
            </Heading>
            <Table.Root>
              <Table.Body>
                {overview.map(({ label, value, info }) => (
                  <Table.Row key={label}>
                    <Table.Cell width={'100%'}>
                      <Flex justifyContent={'space-between'}>
                        <Text>
                          {label}
                          {info && (
                            <Tooltip content={info} positioning={{ placement: 'bottom-start' }}>
                              <Icon w={4} h={4} mb="-3px" ml={0.5}>
                                <MdInfo />
                              </Icon>
                            </Tooltip>
                          )}
                        </Text>
                        <Text fontWeight={500}>{formatCurrency(value || 0)}</Text>
                      </Flex>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table.Root>
          </DialogBody>
          <DialogFooter>
            <HStack>
              <Button disabled={isPending} variant="outline" color="secondary" w="70px" onClick={onClose}>
                Cancel
              </Button>
              <Button w="90px" colorPalette="blue" disabled={isPending} loading={isPending} onClick={() => mutate(ids)}>
                File
              </Button>
            </HStack>
          </DialogFooter>
        </DialogContent>
      </DialogRoot>
    </>
  );
};

import { Flex, Grid, GridItem, Skeleton } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { FILINGS_METRICS_KEY, getFilingMetrics } from 'apis/filing-apis';
import { useOrg } from 'hooks/useOrg';
import MiniStatistics from 'pages/Dashboard/components/mini-statistics';
import { formatCurrency } from 'utils/utils';

const FilingVanityMetric = () => {
  const { orgId } = useOrg();
  const { isPending: isFilingMetricsPending, data: filingMetrics } = useQuery({
    queryKey: [FILINGS_METRICS_KEY, orgId],
    queryFn: async () => {
      const { data } = await getFilingMetrics(orgId);
      return data;
    },
  });

  if (isFilingMetricsPending) {
    return (
      <Flex gap={4}>
        <Skeleton flex="1" height="100px" />
        <Skeleton flex="1" height="100px" />
        <Skeleton flex="1" height="100px" />
        <Skeleton flex="1" height="100px" />
      </Flex>
    );
  }

  const {
    total_collected_tax = 0,
    total_remitted_tax = 0,
    total_calculated_tax = 0,
    total_tax_liability = 0,
  } = filingMetrics || {};
  const metrics = [
    { title: 'Collected Tax', amount: formatCurrency(total_collected_tax) },
    { title: 'Remitted Tax', amount: formatCurrency(total_remitted_tax) },
    { title: 'Calculated Tax', amount: formatCurrency(total_calculated_tax) },
    { title: 'Outstanding Liability', amount: formatCurrency(total_tax_liability) },
  ];

  return (
    <Grid templateColumns={{ base: '1fr', sm: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' }} width="100%" mb={4}>
      {metrics.map((metric, index) => (
        <GridItem key={metric.title}>
          <MiniStatistics
            title={metric.title}
            amount={metric.amount}
            cardStyle={{
              borderRadius: '0px',
              height: '100%',
            }}
            tooltipText={
              index === 3
                ? 'This is the sum of the filing amounts corresponding to all the filings that have not been filed and remitted.'
                : undefined
            }
          />
        </GridItem>
      ))}
    </Grid>
  );
};

export default FilingVanityMetric;

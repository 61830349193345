import { Box, IconButton, Spinner } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { exportReportForFiling } from 'apis/filing-apis';
import { MenuContent, MenuItem, MenuRoot, MenuTrigger } from 'components/ui/menu';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import React from 'react';
import { MdMoreVert } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { Maybe } from 'types/utils';

type FilingTableActionsProps = {
  filingId: string;
  visibility: 'visible' | 'hidden';
  stateCode: Maybe<string>;
  countryCode: Maybe<string>;
};

export const FilingTableActions = ({
  filingId,
  visibility,
  stateCode = '',
  countryCode = '',
}: FilingTableActionsProps) => {
  const { orgId } = useOrg();
  const navigate = useNavigate();
  const { handleSuccessNotification } = useHandleNotification();

  const downloadFile = (url: string, filename: string) => {
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  };

  const { mutate: doExportFiling, isPending } = useMutation({
    mutationFn: () => {
      return exportReportForFiling(orgId, filingId);
    },
    onSuccess: response => {
      if (import.meta.env.VITE_ENVIRONMENT === 'local') {
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const url = window.URL.createObjectURL(blob);
        const contentDisposition = response.headers['content-disposition'];
        let filename = 'download'; // Default filename

        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename="?(.+)"?/i);
          if (filenameMatch?.[1]) {
            filename = filenameMatch[1].replace(/"/g, '');
          }
        }
        downloadFile(url, filename);
        handleSuccessNotification('Filing Detail Report Downloaded');
      } else {
        handleSuccessNotification('Filing Detail Report Export Started. Check your email for a download link.');
      }
    },
  });

  const handleViewTransactions = () => {
    navigate(`/transactions?state_code=${stateCode}&country=${countryCode}`);
  };

  const handleDownloadReport = () => {
    doExportFiling();
  };

  if (isPending) {
    return (
      <Box width={'40px'}>
        <Spinner p={[2, 2]} size="sm" />
      </Box>
    );
  }

  return (
    <MenuRoot
      onSelect={({ value }) => {
        if (value === 'view_transaction') {
          handleViewTransactions();
        }

        if (value === 'download_report') {
          handleDownloadReport();
        }
      }}
    >
      <MenuTrigger asChild>
        <IconButton
          variant={'secondary-outline'}
          visibility={visibility}
          aria-label="Options"
          onClick={ev => {
            ev.stopPropagation();
          }}
        >
          <MdMoreVert size={'24px'} />
        </IconButton>
      </MenuTrigger>
      <MenuContent
        zIndex={'1100'}
        onClick={ev => {
          ev.stopPropagation();
        }}
      >
        <MenuItem value="view_transaction" disabled={!stateCode || !countryCode}>
          View Transactions
        </MenuItem>
        <MenuItem value="download_report">Download Report</MenuItem>
      </MenuContent>
    </MenuRoot>
  );
};

import { useDisclosure } from '@chakra-ui/react';
import { KDataTable } from 'components/table/data-table';
import { TableEmptyState } from 'components/table-empty-state/table-empty-state';
import { TableRowSkeleton } from 'components/ui/table-row-skeleton';
import { useState } from 'react';
import { FilingApprovalStatsByIds, FilingInstance } from 'types/shared-types';

import FilingDetailsDrawer from '../filing-details-drawer';
import FilingTableRow from './filing-table-row';

type FilingTableProps = {
  data: FilingInstance[];
  isPending: boolean;
  approvalStatsByIds?: FilingApprovalStatsByIds;
  haveFillingPageAccess: boolean;
  haveRegistrations: boolean;
};

const TABLE_HEAD = [
  'Country',
  'Jurisdiction',
  'start date',
  'end date',
  'Filed Date',
  'Due Date',
  'total liability',
  'tax collected',
  'filing status',
];
const DEFAULT_VISIBLE_COLUMNS = [
  'Country',
  'Jurisdiction',
  'start date',
  'end date',
  'Due Date',
  'total liability',
  'filing status',
];

const NUMERIC_COLS = ['total liability', 'tax collected'];

const COLUMN_WIDTHS = {
  Country: '100px',
  Jurisdiction: '100px',
  'start date': '100px',
  'end date': '100px',
  'Due Date': '100px',
  'total liability': '150px',
  'filing status': '110px',
};

export const FilingTable = ({
  data,
  approvalStatsByIds,
  haveFillingPageAccess,
  isPending,
  haveRegistrations,
}: FilingTableProps) => {
  const isDataEmpty = !data || data.length === 0;
  const { open, onOpen, onClose } = useDisclosure();
  const [visibleColumns, setVisibleColumns] = useState<string[]>(DEFAULT_VISIBLE_COLUMNS);
  const [selectedRowData, setSelectedRowData] = useState<FilingInstance | null>(null);

  const handleToggleColumn = (columns: string[]) => {
    setVisibleColumns(columns);
  };

  if (!haveFillingPageAccess && !isPending) {
    return (
      <TableEmptyState
        tableName="Filings"
        tableUniqueMessage="No Filings Available"
        uniqueHelperTxt="Please upgrade to a paid plan to register and file."
      />
    );
  } else if (haveFillingPageAccess && haveRegistrations && isDataEmpty && !isPending) {
    return (
      <TableEmptyState
        tableName="Filings"
        tableUniqueMessage="No Filings Available"
        uniqueHelperTxt="Filings for a current period are generated on the 4th of the following month. Please check back again later."
      />
    );
  } else if (haveFillingPageAccess && !haveRegistrations && isDataEmpty && !isPending) {
    return (
      <TableEmptyState
        tableName="Filings"
        tableUniqueMessage="No Filings Available"
        uniqueHelperTxt="Please register in a jurisdiction or import existing registrations to generate filings."
      />
    );
  }

  return (
    <>
      <KDataTable
        headers={TABLE_HEAD}
        numericCols={NUMERIC_COLS}
        defaultVisibleColumns={visibleColumns}
        onVisibleColumnsChange={handleToggleColumn}
        showColumnFilter
        columnWidths={COLUMN_WIDTHS}
        isPending={isPending}
      >
        {data.map((row: FilingInstance) => {
          const approvalStatus = approvalStatsByIds ? approvalStatsByIds[row.id] : undefined;
          return (
            <FilingTableRow
              key={row.id}
              rowData={row}
              isApproveButtonLoading={isPending}
              approvalStatus={approvalStatus}
              visibleColumns={visibleColumns}
              onOpenFilingDetailsDrawer={onOpen}
              setSelectedRowData={setSelectedRowData}
            />
          );
        })}
        {isPending && <TableRowSkeleton length={25} columns={visibleColumns} />}
      </KDataTable>
      {open && selectedRowData && (
        <FilingDetailsDrawer isOpenDrawer={open} onCloseDrawer={onClose} selectedFilingId={selectedRowData.id} />
      )}
    </>
  );
};

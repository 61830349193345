import { CountryCodeEnum } from '_api-client';
import { createListCollection, DialogBody, HStack, ListCollection, Stack } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createFilingApi, FILINGS_STATE_KEY } from 'apis/filing-apis';
import { Button } from 'components/ui/button';
import DatePicker from 'components/ui/date-picker';
import {
  DialogBackdrop,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { Field } from 'components/ui/field';
import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from 'components/ui/select';
import { Tooltip } from 'components/ui/tooltip';
import { subDays } from 'date-fns';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import useTracking from 'hooks/useTracking';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FilingInstanceCreate, FilingStateOption, FilingStatus } from 'types/shared-types';
import { getCountryNameByCode } from 'utils';
import { isValidDate } from 'utils/dates';
import { getHumanReadableString } from 'utils/enum-helpers';
import { date, object, ref, string } from 'yup';

const validationSchema = object().shape({
  registration_id: string().required('State is required'),
  start_date: date().required('Start date is required'),
  end_date: date().min(ref('start_date'), "End date can't be before start date").required('End date is required'),
  status: string().oneOf(Object.values(FilingStatus)),
  state_name: string().required('State Name is required'),
  state_code: string().required('State Code is required'),
  country_code: string().required('Country Code is required'),
});

type FilingTypeProps = {
  isOpen: boolean;
  onClose: () => void;
  registrationCountryStates: FilingStateOption[];
};

export const FilingForm = ({ isOpen, onClose, registrationCountryStates }: FilingTypeProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { orgId } = useOrg();
  const { track } = useTracking();
  const queryClient = useQueryClient();
  const { handleSuccessNotification } = useHandleNotification();

  const [filingStates, setFilingStates] = useState<ListCollection<FilingStateOption>>();

  const mutation = useMutation({
    mutationFn: (payload: FilingInstanceCreate) => {
      return createFilingApi(orgId, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [FILINGS_STATE_KEY] });
      onClose();
      resetForm();
      handleSuccessNotification('Filing added successfully.');
    },
  });

  const { values, handleSubmit, setFieldValue, resetForm, errors, touched } = useFormik({
    initialValues: {
      registration_id: '',
      status: FilingStatus.UNFILED,
      start_date: '',
      end_date: '',
      is_manual: true,
      state_name: '',
      state_code: '',
      country_code: CountryCodeEnum.US,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      const newValues: typeof values & { date_filed?: string } = { ...values };
      if (values.status === FilingStatus.FILED) {
        newValues.date_filed = values.end_date;
      }
      mutation.mutate(newValues);
      track('files jurisdiction');
    },
  });

  const countryCollection = useMemo(() => {
    return createListCollection({
      items: [...new Set(registrationCountryStates.map(option => option.country_code))].map(code => ({
        label: getCountryNameByCode(code),
        value: code,
      })),
    });
  }, [registrationCountryStates]);

  useEffect(() => {
    const filteredStates = createListCollection({
      items: registrationCountryStates
        .filter(state => state.country_code === values.country_code)
        .map(regItem => ({
          label: regItem.state_name,
          value: regItem.state_code,
          ...regItem,
        })),
    });
    setFilingStates(filteredStates);
  }, [registrationCountryStates, values.country_code]);

  const filingCollection = useMemo(() => {
    return createListCollection({
      items: Object.values(FilingStatus).map(option => ({
        label: getHumanReadableString(option),
        value: option,
      })),
    });
  }, [FilingStatus]);

  const handleModalClose = () => {
    onClose();
    resetForm();
  };

  return (
    <DialogRoot
      size={'md'}
      open={isOpen}
      onOpenChange={({ open }) => {
        if (!open) {
          handleModalClose();
        }
      }}
    >
      <DialogBackdrop />
      <DialogContent ref={contentRef}>
        <DialogHeader>
          <DialogTitle>New Filing</DialogTitle>
        </DialogHeader>
        <DialogCloseTrigger />
        <DialogBody>
          <form noValidate>
            <Stack gap={'4'}>
              <HStack align={'baseline'} gap={4}>
                <Field label="Country" required>
                  <SelectRoot
                    collection={countryCollection}
                    value={values.country_code ? [values.country_code] : undefined}
                    onValueChange={({ value }) => {
                      setFieldValue('country_code', value[0]);
                      //Resetting jurisdiction-related fields when country changes
                      setFieldValue('registration_id', '');
                      setFieldValue('state_name', '');
                      setFieldValue('state_code', '');
                      setFieldValue('start_date', '');
                      setFieldValue('end_date', '');
                    }}
                  >
                    <SelectTrigger>
                      <SelectValueText />
                    </SelectTrigger>
                    <SelectContent>
                      {countryCollection.items.map(item => (
                        <SelectItem key={item.value} item={item}>
                          {item.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </SelectRoot>
                </Field>
                <Field
                  label="Jurisdiction"
                  invalid={!!(errors.registration_id && touched.registration_id)}
                  errorText={errors.registration_id}
                  required
                >
                  <SelectRoot
                    collection={filingStates || createListCollection({ items: [] })}
                    value={values.state_code ? [values.state_code] : undefined}
                    onValueChange={({ value }) => {
                      const selectedItem = filingStates?.items.find(({ state_code }) => state_code === value[0]);
                      if (selectedItem) {
                        setFieldValue('registration_id', selectedItem.registration_id);
                        setFieldValue('state_name', selectedItem.state_name || '');
                        setFieldValue('state_code', selectedItem.state_code || '');
                        setFieldValue('start_date', selectedItem.registration_date || '');
                        setFieldValue('end_date', '');
                      }
                    }}
                  >
                    <SelectTrigger>
                      <SelectValueText placeholder="Select" />
                    </SelectTrigger>
                    <SelectContent portalRef={contentRef}>
                      {filingStates?.items.map(item => (
                        <SelectItem key={item.value} item={item}>
                          {item.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </SelectRoot>
                </Field>
              </HStack>
              <HStack align={'baseline'} gap={4}>
                <Field
                  label="Start Date"
                  invalid={!!(errors.start_date && touched.start_date)}
                  errorText={errors.start_date}
                  required
                >
                  <DatePicker
                    minDate={
                      new Date(
                        registrationCountryStates?.find(el => el.registration_id === values.registration_id)
                          ?.registration_date || ''
                      )
                    }
                    maxDate={isValidDate(new Date(values.end_date)) ? subDays(new Date(values.end_date), 1) : null}
                    selected={values.start_date}
                    onChange={date => {
                      setFieldValue('start_date', date);
                    }}
                    renderDayContents={(day, date) => {
                      const registrationDate = registrationCountryStates?.find(
                        el => el.registration_id === values.registration_id
                      )?.registration_date;

                      return (
                        <div>
                          <Tooltip
                            content={
                              registrationDate && date && new Date(date) < new Date(registrationDate)
                                ? 'Please select a date after the registration date for this jurisdiction.'
                                : ''
                            }
                          >
                            <span>{day}</span>
                          </Tooltip>
                        </div>
                      );
                    }}
                  />
                </Field>
                <Field
                  label="End Date"
                  invalid={!!(errors.end_date && touched.end_date)}
                  errorText={errors.end_date}
                  required
                >
                  <DatePicker
                    minDate={new Date(values.start_date)}
                    selected={values.end_date}
                    onChange={date => {
                      setFieldValue('end_date', date);
                    }}
                    limitToLastDay={true}
                  />
                </Field>
                <Field label="Status" invalid={!!(errors.status && touched.status)} errorText={errors.status} required>
                  <SelectRoot
                    collection={filingCollection}
                    value={values.status ? [values.status] : undefined}
                    onValueChange={({ value }) => setFieldValue('status', value[0])}
                  >
                    <SelectTrigger>
                      <SelectValueText />
                    </SelectTrigger>
                    <SelectContent portalRef={contentRef}>
                      {filingCollection.items.map(item => (
                        <SelectItem key={item.value} item={item}>
                          {item.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </SelectRoot>
                </Field>
              </HStack>
            </Stack>
          </form>
        </DialogBody>
        <DialogFooter>
          <Button variant={'outline'} color={'secondary'} onClick={handleModalClose}>
            Cancel
          </Button>
          <Button
            loading={mutation.isPending}
            variant={'solid'}
            colorPalette="blue"
            width={'90px'}
            onClick={() => handleSubmit()}
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

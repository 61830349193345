import { useDisclosure } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getAllRegistrations, REGISTRATION_STATE_KEY } from 'apis/registration-apis';
import { usePaywall } from 'app/acl/paywall';
import { Button } from 'components/ui/button';
import { Tooltip } from 'components/ui/tooltip';
import { useOrg } from 'hooks/useOrg';
import useQueryParam from 'hooks/useQueryParam';
import { RegistrationStatusEnum } from 'schema/types-schema.d';
import { FilingStateOption } from 'types/shared-types';

import { FilingForm } from './filing-form';

export const CreateFiling = () => {
  const { open, onOpen, onClose } = useDisclosure();
  const { orgId, isTest } = useOrg();
  const { isSubscriptionPausedOrCanceled, isPaidUser } = usePaywall();
  const [, setSearchParams] = useQueryParam('');
  const filter = {
    status: [RegistrationStatusEnum.REGISTERED],
  };

  const { isLoading, data = [] } = useQuery({
    queryKey: [REGISTRATION_STATE_KEY, 'filings', 'registered', orgId, isPaidUser, isSubscriptionPausedOrCanceled],
    queryFn: () => {
      return getAllRegistrations({
        orgId,
        filter,
      });
    },
    select: data => {
      return data.map(
        ({ id, country_code, state_name, state_code, registration_date }) =>
          ({
            registration_id: id,
            country_code,
            state_name,
            state_code,
            registration_date,
          }) as FilingStateOption
      );
    },
    enabled: !isSubscriptionPausedOrCanceled,
  });

  const isDataEmpty = data?.length === 0;

  const handleClick = () => {
    if (isPaidUser || isTest) {
      onOpen();
    } else {
      setSearchParams({ openPricingModal: 'true' });
    }
  };

  return (
    <>
      <Tooltip
        openDelay={100}
        content={
          isSubscriptionPausedOrCanceled
            ? 'You need to activate subscription for de-register'
            : 'Please register in a jurisdiction to start filing'
        }
        disabled={!isSubscriptionPausedOrCanceled && !isDataEmpty}
      >
        <Button
          loading={isLoading}
          variant={'solid'}
          colorPalette={'blue'}
          width={'100px'}
          onClick={handleClick}
          disabled={isDataEmpty || isSubscriptionPausedOrCanceled}
        >
          New
        </Button>
      </Tooltip>
      {!isLoading && !isSubscriptionPausedOrCanceled && (
        <FilingForm isOpen={open} onClose={onClose} registrationCountryStates={data} />
      )}
    </>
  );
};

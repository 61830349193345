import { Flex, Heading, IconButton } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { FILINGS_BY_ID_STATE_KEY, getFilingForId } from 'apis/filing-apis';
import { ArrowLeft } from 'components/icons';
import { DrawerBackdrop, DrawerBody, DrawerContent, DrawerHeader, DrawerRoot } from 'components/ui/drawer';
import { Skeleton } from 'components/ui/skeleton';
import { useOrg } from 'hooks/useOrg';
import useQueryParam from 'hooks/useQueryParam';

import FilingDetails from './filing-details';

type FilingDetailsDrawerProps = {
  isOpenDrawer?: boolean;
  onCloseDrawer?: () => void;
  selectedFilingId?: string;
};

const FilingDetailsDrawer = ({ isOpenDrawer, onCloseDrawer, selectedFilingId }: FilingDetailsDrawerProps) => {
  const [showFilingDetails, setShowFilingDetailsParams] = useQueryParam('showFilingDetails');
  const [showFilingHistory, setShowFilingHistoryParams] = useQueryParam('showFilingHistory');
  const [paramFilingId] = useQueryParam('filingId');
  const { orgId } = useOrg();

  const filingId = paramFilingId || selectedFilingId;
  const onClose = () => {
    if (showFilingHistory) {
      setShowFilingHistoryParams({ showFilingHistory: 'true' });
      onCloseDrawer?.();
      return;
    }
    setShowFilingDetailsParams({});
    onCloseDrawer?.();
  };

  const { isPending: isPendingFilingDetails, data: filingDetails } = useQuery({
    enabled: (isOpenDrawer || !!showFilingDetails) && !!filingId,
    queryKey: [FILINGS_BY_ID_STATE_KEY, filingId],
    queryFn: async () => {
      const res = await getFilingForId(orgId, filingId);
      return res.data;
    },
  });

  return (
    <DrawerRoot open={isOpenDrawer || !!showFilingDetails} size="md">
      <DrawerBackdrop />
      <DrawerContent>
        <DrawerHeader>
          <Flex gap={2} alignItems={'center'} justifyContent={'flex-start'}>
            <IconButton rounded={'sm'} size="xs" variant={'ghost'} aria-label="back-to-filings" onClick={onClose}>
              <ArrowLeft />
            </IconButton>
            <Heading>Filing Details</Heading>
          </Flex>
        </DrawerHeader>
        <DrawerBody>{isPendingFilingDetails ? <Skeleton /> : <FilingDetails data={filingDetails} />}</DrawerBody>
      </DrawerContent>
    </DrawerRoot>
  );
};

export default FilingDetailsDrawer;

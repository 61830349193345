import { Badge } from '@chakra-ui/react';
import { FilingStatus } from 'types/shared-types';

type FilingStateBadgeProps = {
  status: FilingStatus;
};

export default function FilingStateBadge({ status }: FilingStateBadgeProps) {
  if (status === FilingStatus.UNFILED) {
    return <Badge colorPalette={'gray'}>Unfiled</Badge>;
  } else if (status === FilingStatus.FILING) {
    return <Badge colorPalette={'gray'}>Processing</Badge>;
  } else if (status === FilingStatus.FILED) {
    return <Badge colorPalette={'green'}>Filed</Badge>;
  } else {
    return null;
  }
}

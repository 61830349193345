import { Box, Flex, Text } from '@chakra-ui/react';
import { exportReportForFiling } from 'apis/filing-apis';
import { usePaywall } from 'app/acl/paywall';
import AlertBanner from 'components/alert/alert';
import { TableEmptyState } from 'components/table-empty-state/table-empty-state';
import { Button } from 'components/ui/button';
import { FormikProps } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import useAsyncActionLoader from 'hooks/useAsyncActionLoader';
import { useOrg } from 'hooks/useOrg';
import { AutoFileRegisterToggle } from 'pages/Configuration/components/org-settings/auto-file-register-toggle';
import React from 'react';
import { MdOutlineFileDownload } from 'react-icons/md';
import { FilingDetailsInstance } from 'types/shared-types';
import { toDateShort } from 'utils/dates';
import { formatCurrency } from 'utils/utils';

import { AttachmentCard } from './attachment-card';
import { OverviewTable } from './overview-table';

type FormValues = {
  auto_file: boolean;
  auto_register: boolean;
};

const FilingDetails: React.FC<{
  data: FilingDetailsInstance;
  shouldShowSubheader?: boolean;
  formik?: FormikProps<FormValues>;
  shouldShowAutoFile?: boolean;
}> = ({ data, shouldShowSubheader, formik, shouldShowAutoFile }) => {
  const { orgId } = useOrg();
  const { isPaidUser } = usePaywall();
  const { loading, executeAction } = useAsyncActionLoader(exportReportForFiling);
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();

  const {
    id,
    start_date,
    end_date,
    due_date,
    amount,
    amount_calculated,
    amount_adjusted,
    amount_discounts,
    amount_tax_collected,
    amount_fees,
    amount_penalties,
    amount_sales,
    can_file,
    state_name,
    total_tax_liability,
    transaction_count,
    tax_remitted,
    return_confirmation_id,
    payment_confirmation_id,
    internal_notes,
    attachments,
    credits_utilized,
  } = data || {};

  const isCollectedTaxHigher = Number(amount_tax_collected || 0) > Number(amount_calculated || 0);

  const periodOverviewData = [
    { label: 'Jurisdiction', value: state_name },
    { label: 'Due Date', value: toDateShort(due_date) },
    { label: 'Period', value: `${toDateShort(start_date)} - ${toDateShort(end_date)}` },
    { label: 'Transaction Count', value: transaction_count },
    { label: 'Transaction Amount', value: formatCurrency(amount_sales ?? 0) },
  ];

  const taxOverviewTableData = [
    {
      label: isCollectedTaxHigher ? 'Collected Tax' : 'Calculated Tax',
      value: amount,
      info: `Calculated Tax = ${formatCurrency(amount_calculated ?? 0)}`,
    },
    { label: 'Adjustment', value: formatCurrency(amount_adjusted ?? 0) },
    { label: 'Fees', value: formatCurrency(amount_fees ?? 0) },
    { label: 'Penalties', value: formatCurrency(amount_penalties ?? 0) },
    { label: 'Discount', value: formatCurrency(amount_discounts ?? 0) },
    { label: 'Credit Utilized', value: formatCurrency(credits_utilized ?? 0) },
    {
      label: 'Total Liability',
      value: formatCurrency(total_tax_liability ?? 0),
      info: 'Total Liability is calculated as the maximum of Calculated Tax or Collected Tax, plus any Adjustments, Penalties, and Fees, minus any applicable Discounts.',
    },
  ];

  const remittanceOverviewData = [
    { label: 'Tax Remitted', value: formatCurrency(tax_remitted ?? 0) },
    { label: 'Return Confirmation ID', value: return_confirmation_id },
    { label: 'Payment Confirmation ID', value: payment_confirmation_id },
  ];

  const handleDownloadClick = async () => {
    try {
      await executeAction(orgId, id || '');
      handleSuccessNotification(
        'The report will be sent to your email address and will arrive in the next 1-2 minutes depending on how much data is in your organization.'
      );
    } catch (error) {
      handleFailNotification(error);
    }
  };

  if (!data) {
    return <TableEmptyState tableName="Filing Details" />;
  }

  return (
    <Flex direction="column" gap={4}>
      {shouldShowSubheader && (
        <Text>
          Please review the details and submit when you are ready to file {formatCurrency(total_tax_liability)}
        </Text>
      )}
      {!can_file && (
        <AlertBanner message="For this filing period there are unapproved products and invalid addresses, please resolve this before continuing." />
      )}

      <OverviewTable title="Period Overview" data={periodOverviewData} />
      <OverviewTable title="Tax Overview" data={taxOverviewTableData} />
      <OverviewTable title="Remittance Overview" data={remittanceOverviewData} />

      <Flex flexDirection={'column'} gap={2}>
        <Text textStyle={'lg'} fontWeight={'500'}>
          Attachments
        </Text>
        {attachments ? (
          Object.entries(attachments)?.map(([key, attachment]) => (
            <AttachmentCard key={key} attachmentName={attachment} attachmentId={key} />
          ))
        ) : (
          <Text>No attachments found</Text>
        )}
      </Flex>

      <Flex flexDirection={'column'} gap={2}>
        <Text textStyle={'lg'} fontWeight={'500'}>
          Note
        </Text>
        <Box border={'1px solid'} borderColor="#efeff3" p={2} borderRadius={4}>
          <Text>{internal_notes ?? 'No notes found'}</Text>
        </Box>
      </Flex>

      <span>
        <Button onClick={handleDownloadClick} loading={loading} variant="outline" color={'secondary'}>
          <MdOutlineFileDownload fontSize={'20px'} />
          Download Report
        </Button>
      </span>

      {isPaidUser && shouldShowSubheader && shouldShowAutoFile && (
        <AutoFileRegisterToggle
          label="Auto File"
          fieldName="auto_file"
          formik={formik}
          mt={0}
          p={2}
          fontSize={'sm'}
          tooltip={
            <>
              <Text color={'white'} mb={2}>
                Automatically approve all the filings on the 10th of the month. You can toggle this OFF anytime from
                Settings.
              </Text>
              <Text color={'white'}>
                Please ensure you complete your tasks, such as validating addresses and approving products so that
                filings can be auto-approved.
              </Text>
            </>
          }
        />
      )}
    </Flex>
  );
};

export default FilingDetails;

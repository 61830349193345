import { Card, CardRootProps, Flex, StatLabel } from '@chakra-ui/react';
import { InfoFilled } from 'components/icons';
import { StatRoot, StatValueText } from 'components/ui/stat';
import { Tooltip } from 'components/ui/tooltip';

type Props = {
  title: string;
  amount: string;
  cardStyle?: CardRootProps;
  tooltipText?: string;
};

const MiniStatistics = ({ title, amount, cardStyle, tooltipText }: Props) => (
  <Card.Root minH="83px" borderColor="gray.100" {...cardStyle}>
    <Card.Body>
      <Flex flexDirection="row" align="center" justify="center" w="100%">
        <StatRoot size="lg" me="auto">
          <StatLabel fontSize="md" color={'gray.800'}>
            {title}
            {tooltipText && (
              <Tooltip content={tooltipText}>
                <InfoFilled />
              </Tooltip>
            )}
          </StatLabel>
          <Flex>
            <StatValueText fontSize="4xl">{amount}</StatValueText>
          </Flex>
        </StatRoot>
      </Flex>
    </Card.Body>
  </Card.Root>
);

export default MiniStatistics;
